//File which will specifically implement the styling for the loader component
//This has been separated as there is a lot of code, which is only applied to the loader component
.loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height : 100vh;
    display: flex;
    z-index: 1000;
    justify-content: center;
    align-items: center;
    background-color: #f7f9fb;
    transition: opacity 3s, visibility 3s;
}


.loader-hidden{
    opacity: 0;
    visibility: hidden;
}


.loader::after{
    content: "";
    width: 75px;
    height: 75px;
    border: 15px solid #dddddd;
    border-top-color: #7449f5;
    border-radius: 50%;
    animation: loading 2s ease infinite;
}


@keyframes loading{
    from{
        transform: rotate(0turn);
    }
    to{
        transform: rotate(1turn);
    }
}
