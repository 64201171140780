//Code to specifically design the background image of the HomeScreen
//Seperated at there is a lot of code, which wouldn't be needed elsewhere in the program
#HomeScreen{
    background : linear-gradient(90deg, rgb(0, 119, 255) 0%, rgb(14, 132, 168) 50%, rgb(153, 198, 207) 100%);
    overflow: hidden;
}

/* Making the squares to move up the screen */
.animatedSquare{
    position: absolute;
    width: 60px;
    height: 60px;
    background-color: transparent;
    border : 6px solid rgba(255,255,255,0.8)
}

.animatedSquare:nth-child(1){
    /* This defines the starting position, can be random really*/
    top: 12%;
    left: 42%;
    /* The animation time can be random aswell, as isn't really important*/
    animation : animate 10s linear infinite
}


.animatedSquare:nth-child(2){
    top: 70%;
    left: 50%;
    animation : animate 7s linear infinite
}


.animatedSquare:nth-child(3){
    top: 17%;
    left: 6%;
    animation : animate 9s linear infinite
}

.animatedSquare:nth-child(4){
    top: 80%;
    left: 90%;
    animation : animate 10s linear infinite
}


.animatedSquare:nth-child(5){
    top: 22%;
    left: 80%;
    animation : animate 6s linear infinite
}


.animatedSquare:nth-child(6){
    top: 82%;
    left: 10%;
    animation : animate 7s linear infinite
}


.animatedSquare:nth-child(7){
    top: 66%;
    left: 66%;
    animation : animate 9s linear infinite
}


.animatedSquare:nth-child(8){
    top: 12%;
    left: 90%;
    animation : animate 10s linear infinite
}

.animatedSquare:nth-child(9){
    top: 40%;
    left: 15%;
    animation : animate 7s linear infinite
}


.animatedSquare:nth-child(10){
    top: 32%;
    left: 60%;
    animation : animate 8s linear infinite
}


@keyframes animate{
    /* Animation moves up, increases the size of and rotates the squares*/
    0%{
        transform: scale(0) translateY(0) rotate(0);
        opacity: 1;
    }
    100%{
        transform: scale(1.3) translateY(-90px) rotate(360deg);
        opacity: 0;
    }
}

